<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="headline">{{ $tc('label.apuracao_acao_detalhes_foco', 1)}}</v-expansion-panel-header>
    <v-expansion-panel-content>
      <card-info :campos="campos" :objeto="formatarObjeto()" />
      <div v-if="acao.reservas">
        <v-row v-if="parametrizadosComposOi() && acao.reservas"
          v-for="(reserva, index) in acao.reservas[0].contas"
          :key="index"
          track-by="$index">
          <v-col cols="3" v-if="descOi">
            <v-text-field
              id="$index"
              name="reserva_descricao"
              v-model="reserva.descricaoLinha"
              class="Form-text__invalid"
              :label="`${$tc('label.descricao', 1)}`"
              :counter="50"
              maxlength="50"
              :disabled="true">
            </v-text-field>
          </v-col>
          <v-col cols="3" v-if="valorOi">
            <v-text-field
              id="$index"
              name="reserva_valor"
              v-bind:value="getMoney(reserva.valor)"
              class="Form-text__invalid"
              :label="`${$tc('label.valor_reservado', 1)}`"
              :counter="50"
              maxlength="50"
              :disabled="true">
            </v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import CardInfo from '../../shared-components/CardInfo';
import { getMoney } from '../../common/functions/helpers';

export default {
  name: 'ApuracaoAcaoDetalhesFoco',
  props: {
    acao: Object,
    configuracao: Object,
    campoCliente: {
      type: String,
      default: '',
    },
  },
  components: {
    CardInfo,
  },
  data() {
    return {
      descOi: false,
      valorOi: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  computed: {
    campos() {
      return [
        { campo: 'cliente', label: this.$tc('label.cliente', 1), hint: 'cnpj' },
        { campo: 'holding', label: this.$tc('label.holding', 1) },
        { campo: 'regional', label: this.$tc('label.regional', 1) },
        { campo: 'campoCliente', label: this.$tc(`label.${this.campoCliente}`, 1) },
        { campo: 'clientePagador', label: this.$tc('label.cliente_pagador', 1), hint: 'cnpjPagador' },
        { campo: 'fornecedorPagador', label: this.$tc('label.fornecedor_pagador', 1), hint: 'cnpjPagador' },
        { campo: 'tipoPagamento', label: this.$tc('label.tipo_pagamento', 1), i18n: true },
        { campo: 'tiposVerbas', label: this.$tc('label.tipo_de_verba', 1) },
        { campo: 'projeto', label: this.$tc('label.projeto', 1) },
      ];
    },
  },
  methods: {
    getMoney,
    formatarObjeto() {
      const acaoFormatada = { ...this.acao };
      if (this.acao && this.acao.tipoPagamento) {
        acaoFormatada.tipoPagamento = this.$tc(`tipos_pagamentos.${this.acao.tipoPagamento.toUpperCase()}`, 1);
      }
      return acaoFormatada;
    },
    parametrizadosComposOi() {
      this.descOi = this.configuracao.passo3.listaDinamica.find((item) => item.label === 'descricao_oi');
      this.valorOi = this.configuracao.passo3.listaDinamica.find((item) => item.label === 'valor_oi');
      return this.descOi || this.valorOi;
    },
  },
};
</script>
